.cabinet-layout{
  min-height: calc(100vh - 7.2rem);
  &__menu{
    max-width: 25rem;
    width: 100%;
    padding: 8.1rem 1.5rem 2rem;
    box-sizing: border-box;
    border-top: 1px solid var(--line);
    background: var(--white);
  }
  &__menu-item{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1.5rem 1.7rem 1.4rem 2.5rem;
    border-radius: .8rem;
    transition: var(--mSpeed);
    &:hover{
      background: var(--brand-bg-light);
    }
    span{
      color: var(--font-primary);
    }
    svg{
      margin-right: 1.6rem;
      fill: var(--font-primary);
    }
    &.active{
      &:hover{
        cursor: default;
        background: transparent;
      }
      svg{
        fill: var(--brand);
      }
      span{
        font-weight: 700;
        color: var(--brand);
      }
    }
  }
  &__content{
    flex: 1;

    max-width: 145rem;
    width: calc(100% - 20.8rem);
    min-height: inherit;
    padding: 2rem 4rem 4rem 2rem;
    box-sizing: border-box;

    .page-loader{
      width: 100%;
      height: 100%;
    }
  }
  @include breakpoint(md){
    &__menu{
      display: none;
    }
    &__content{
      width: 100%;
      padding: 2rem;
    }
  }
}

.empty-layout{
  max-width: 150rem;
  height: calc(100vh - 8rem);
  position: relative;
  display: flex;
  align-items: center;
  &__logo{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 0;
  }
  &__content{
    position: relative;
    margin: 0 8rem 0 auto;
    z-index: 1;
  }
}

.dashboard-card{
  --p: 2.5rem 2rem 2rem;

  height: 100%;

  &__title-wrapper{
    margin-right: -1rem;
  }
  &__title-wrapper{
    //margin-top: 1rem;
  }
  &__title{
    display: flex;
  }
  &__content-wrapper{
    position: relative;

    margin-top: 1.5rem;
    height: calc(100% - 4.6rem - 1.5rem);
    box-sizing: border-box;
    & > .row{
      margin-bottom: -2rem;
    }
  }
}

.dashboard-balance{
  &__image{
    width: 2.4rem;
    height: 2.4rem;
    img{
      width: 100%;
    }
  }
  &__name{
    display: block;
    margin-top: .4rem;
  }
  &__amount{
    display: flex;
    flex-wrap: wrap;
    span{
      margin-right: 2px;
    }
  }
}

.dashboard-rate{
  padding: 2rem;
  margin-top: .4rem;
  &:not(:last-child){
    margin-right: 1rem;
  }
  &__image{
    width: 4.8rem;
    height: 4.8rem;
    img{
      width: 100%;
    }
  }
  &__rate{
    margin-top: 1rem;
  }
  &__symbol{
    display: block;
    margin-left: .5rem;
  }
  &__daily-change{
    margin-left: .7rem;
  }
}

.dashboard-history{
  margin-left: -.5rem;

  //block for adaptive scroll
  width: 100%;
  max-height: 100%;
  height: 100%;
  position: absolute;

  &__scroll-wrapper{}
  &__item{
    margin: 2rem 0;
    padding-right: .5rem;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__icon{
    width: 2.8rem;
    margin: 0 2.5rem 0 .6rem;
    svg{
      margin: 0 auto;
    }
  }
  &__info{}
  &__summ span{
    white-space: nowrap;
  }
  &__date-wrapper, &__date{
    text-align: right;
  }
  @include breakpoint(xl){
    max-height: 30rem;
    position: relative;
  }
  @include breakpoint(sm){
    &__item{
      display: block;
    }
    &__date-wrapper, &__date{
      text-align: left;
    }
    &__date-wrapper{
      margin-left: 6rem;
    }
  }
}

.view-change{
  &__btn{
    padding: 1.5rem;
    transition: var(--qSpeed);
    background: transparent;
    &:hover{
      background: var(--bg-secondary);
    }
    svg{
      fill: var(--font-disabled);
      transition: var(--qSpeed);
    }
    &.active svg{
      fill: var(--brand);
    }
  }
}

.balances-card{
  padding: 2rem 1.1rem 2rem 3rem;
  &__image-wrapper{
    width: 5.5rem;
    height: 5.5rem;
    margin-right: 2rem;
    img{
      width: 100%;
    }
  }
  &__text-wrapper{}
}

.balances-table{
  &__image-wrapper{
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    img{
      width: 100%;
    }
  }
  &__text-wrapper{}
}

.orders-filter{
  margin-right: 2rem;
  padding: .4rem 1rem;
  transition: var(--qSpeed);
  border-radius: .8rem;
  span{
    color: var(--font-disabled);
  }
  &.active, &:hover{
    background: var(--brand-bg-light);
    .metadata{
      font-weight: bold;
    }
    span{
      color: var(--brand);
    }
  }
}

.order-type{
  display: block;
  width: 7.6rem;
  margin: 0 auto;
  padding: 0 .8rem;
  border: 1px solid transparent;
  border-radius: .4rem;
  &.buy{
    color: var(--success);
    border-color: var(--success);
  }
  &.sell{
    color: var(--error);
    border-color: var(--error);
  }
}

.settings{
  &__interface-params{
    display: flex;
  }
  &__interface-divider{
    width: .2rem;
    height: 4.8rem;
    margin: 0 2.75rem;
    background: var(--line);
  }
  @include breakpoint(lg){
    &__interface-params{
      display: block;
    }
    &__interface-divider{
      height: 2rem;
      background: transparent;
    }
  }
}

.permission{
  &:not(:last-child){
    margin-bottom: 1.3rem;
  }

  &__key{
    flex: 1;
    padding: 1.2rem 1.6rem;
    background: var(--brand-bg-light);
    border: 1px solid transparent;
    border-radius: .8rem;
    overflow-x: auto;
    transition: var(--qSpeed);
    &.shown{
      border-color: var(--font-disabled);
      background: transparent;
    }
  }

  &__qr{
    margin-left: 2rem;
  }

  &__btn{
    width: fit-content;
    display: flex;
    align-items: center;
    margin: .3rem auto 0;
  }
}

.status-dot{
  width: 1rem;
  height: 1rem;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  &.success{
    background: var(--success);
  }
  &.error{
    background: var(--error);
  }
}