.table{
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  &__cell{
    border-width:  0 0 0 0;
    &:not(:first-child){
      padding-left: 1rem;
    }
    &:not(:last-child){
      padding-right: 1rem;
      border-color: transparent;
    }
    > .btn{
      padding: 1rem;
    }
  }

  &__header{
    .table__cell{
      white-space: nowrap;
    }
    span{
      white-space: nowrap;
    }
  }

  &__row{
    .table__cell {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid var(--line);
      span{
        white-space: nowrap;
      }
    }
    &:last-child{
      .table__cell{
        border-color: transparent;
        padding-bottom: 0;
      }
    }
    &.w-hover:hover{
      cursor: pointer;
      background: var(--brand-bg-light);
    }
  }

  &__cell{
    width: auto;
    box-sizing: border-box;
    white-space:nowrap;
  }

  .align-right{
    text-align: right;
  }
  .align-center{
    text-align: center;
    button{
      width: fit-content;
      margin: 0 auto;
    }
  }
  .fit-content{
    max-width:100%;
    width: 1px;
  }
  .summ{
    min-width: 24rem;
  }
  .description{
    min-width: 35rem;
    word-break: break-all;
    span{
      white-space: break-spaces;
    }
  }
}

.hidden-head {
  .table__header{
    visibility: collapse;
  }
}

.hidden-divider{
  .table__cell{
    border-color: transparent;
  }
}

.padding-medium{
  .table__row .table__cell {
    padding-top: 1rem;
  }
  .table__row:not(:last-child) .table__cell{
    padding-bottom: 1rem;
  }
}

.table-scroll {
  max-height: var(--mh);
  > div {
    padding-right: 1rem;
  }
  th {
    background: var(--white);
    position: sticky;
    top: 0;
    z-index: 2;
  }
}