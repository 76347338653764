.clr{
  &--brand{
    color: var(--brand);
  }
  &--brand-dark{
    color: var(--brand-dark);
  }
  &--font-active{
    color: var(--font-active);
  }
  &--font-primary{
    color: var(--font-primary);
  }
  &--font-secondary{
    color: var(--font-secondary);
  }
  &--success{
    color: var(--success);
  }
  &--error{
    color: var(--error);
  }
  &--error{
    color: var(--error);
  }
  &--disabled{
    color: var(--font-disabled);
  }
}

.bg{
  &--brand{
    background: var(--brand-bg);
  }
  &--brand-light{
    background: var(--brand-bg-light);
  }
  &--primary{
    background: var(--bg-primary);
  }
  &--secondary{
    background: var(--bg-secondary);
  }
  &--success{
    background: var(--success-light);
  }
  &--error{
    background: var(--error-light);
  }
  &--warning{
    background: var(--warning-light);
  }
}