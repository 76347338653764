.tabs{
  //padding: 2rem;
  //background: var(--white);
  .tabs-heading{
    border-bottom: 2px solid var(--line);
    &__border{
      bottom: -2px;
    }
  }
}

.tabs-heading{
  width: 100%;
  height: 3.5rem;
  display: flex;
  position: relative;
  &__item{
    margin-right: 3rem;
    margin-bottom: 1.2rem;

    transition: var(--mSpeed);

    span{
      font-weight: 700;
      color: var(--font-disabled);
    }
    &:hover{
      span{
        color: var(--brand);
      }
    }
    &.active{
      span{
        color: var(--brand);
      }
    }
  }
  &__border{
    width: var(--w);
    height: 2px;
    position: absolute;
    bottom: 0;
    left: var(--l);
    background: var(--brand);
    border-radius: .6rem;
    transition: var(--qSpeed);
    transition-timing-function: ease-in-out;
  }
}