.form{
  padding-top: 0.5rem;
  .col{
    margin-bottom: 0;
  }
  .dropdown{
    margin-bottom: 2.2rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  border: 0px;
  -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
}

.field{
  --l: 1.6rem;

  width: 100%;
  height: 4.8rem;
  display: block;
  margin-bottom: .6rem;
  padding-bottom: 1.6rem;
  position: relative;
  &__body{
    width: 100%;
    height: 100%;

    position: relative;

    &:focus-within{
      .field {
        &__bg-layer{
          background: var(--white) !important;
          border-width: 2px;
          border-color: var(--brand) !important;;
        }
        &__label {
          --l: 1.5rem;

          font-size: 1.2rem;
          top: -.6rem;
          background: var(--white);
          color: var(--brand) !important;
          transform: none;
          z-index: 10;
        }
      }
    }
  }
  &__bg-layer{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--bg-secondary);
    border: 1px solid transparent;
    border-radius: .8rem;
    box-sizing: border-box;
    transition: var(--qSpeed);
    z-index: 5;
  }
  &__input{
    width: calc(100% - .4rem);
    height: calc(100% - .4rem);
    position: relative;
    margin: .2rem;
    padding: 1.2rem 4.4rem 1.2rem 1.8rem;
    box-sizing: border-box;
    background: transparent;
    font-family: var(--ff);
    font-size: 1.6rem;
    font-weight: bold;
    border: 0;
    border-radius: .8rem;
    color: var(--font-primary);
    transition: var(--qSpeed);
    z-index: 9;
    &:focus, &:active{
      outline: none;
    }
  }
  &__label{
    padding: 0 .4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--l);
    line-height: 1;
    transition: var(--qSpeed);
    z-index: 6;
  }
  &__icon-left, &__icon-right, &__asset{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  &__icon-left, &__icon-right{
    width: 2.4rem;
    height: 2.4rem;
  }
  &__icon-left{
    left: 1.6rem;
  }
  &__icon-right, &__asset{
    right: 1.6rem;
  }
  &__message{
    width: 100%;
    height: 4rem;
    position: absolute;
    left: 0;
    bottom: 0.1rem;
    padding: 1.35rem 1.6rem 0.6rem;
    box-sizing: border-box;
    background: var(--brand-bg-light);
    border-bottom-left-radius: .8rem;
    border-bottom-right-radius: .8rem;
    opacity: 0;
    transform: translateY(-10%);
    transition: var(--qSpeed);
    z-index: 1;
    &.shown{
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__comment{
    width: 100%;
    height: 4rem;
    position: absolute;
    left: 0;
    bottom: -1.25rem;
    padding: 1.35rem 1.6rem 0.6rem;
    box-sizing: border-box;
    z-index: 0;
  }

  &:hover{
    cursor: text;
  }

  &.hasValue .field{
    &__label {
      --l: 1.5rem;

      font-size: 1.2rem;
      top: -.6rem;
      background: var(--white);
      color: var(--font-disabled);
      transform: none;
      z-index: 10;
    }
    &__bg-layer {
      background: var(--white);
      border-color: var(--font-disabled);
    }
  }

  &.success .field{
    &__bg-layer{
      border-color: var(--success);
      background: var(--white);
    }
    &__label, &__message span{
      color: var(--success);
    }
    &__message{
      background: var(--success-light);
    }
  }
  &.warning .field{
    &__bg-layer{
      border-color: var(--warning);
    }
    &__label, &__message{
      color: var(--warning);
    }
    &__message{
      background: var(--warning-light);
    }
  }
  &.error .field{
    &__bg-layer{
      border-color: var(--error);
    }
    &__label, &__message span{
      color: var(--error);
    }
    &__message{
      background: var(--error-light);
    }
  }
  &.disabled{
    opacity: .95;

    &:hover{
      cursor: default;
    }

    &.hasValue .field{
      &__label{
        font-size: 1.2rem;
        top: -.6rem;
        background: var(--white);
      }
      &__bg-layer{
        border-color: var(--font-disabled);
        background: var(--bg-secondary);
      }
    }

    .field{
      &__bg-layer{
        border-color: transparent;
        background: var(--bg-secondary);
      }
      &__input{
        color: var(--font-disabled);
      }
      &__label{
        font-size: inherit;
        background: transparent;
        color: var(--font-disabled);
      }
      &__icon-left, &__icon-right{
        svg{
          fill: var(--font-disabled);
        }
      }
    }
  }
}

.field--hasLeftIcon .field{
  &__input{
    padding-left: 4.4rem;
  }
  &__label{
    --l: 4.4rem;
  }
}

.field--hasPrefix .field{
  &__input{
    padding-left: var(--l);
    &:focus, &:active{
      padding-left: var(--l);
    }
  }
}

.field--hasMessage{
  padding-bottom: 3.4rem;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}