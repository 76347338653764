html{
  font-size: 10px;
  @media (-webkit-min-device-pixel-ratio: 2) {
    font-size: 9px;
  }
}

body{
  margin: 0;
  font-family: var(--ff);
  color: var(--fort-primary);
  font-size: 1.4rem;
}

main{
  overflow-x: hidden;
}

#root{
  box-sizing: border-box;
  color: var(--fort-primary);
}

section{}

.container{
  //max-width: 37.5rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

div, button{
  > svg{
    display: block;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a{
  text-decoration: none;
}

.fixed-body{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding-right: -8.5px;
}

.full-width{
  width: 100%;
}

.full-height{
  height: 100%;
}

.custom-scroll{
  .ReactVirtualized__List{
    padding-right: 1.5rem;
  }
  > div, > textarea, .ReactVirtualized__List{
    max-height: inherit;
    overflow-y: auto;
    padding-right: 1.5rem;
    /* width */
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      z-index: 10;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      z-index: 10;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--brand);
      border-radius: 2px;
      transition: var(--qSpeed);
      z-index: 10;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      background: var(--brand-dark);
    }
  }
}

.w-shadow{
  box-shadow: var(--default-shadow);
}