body{
  min-height: 100vh;
}

#root{
  min-height: 100vh;
  background: var(--brand-bg-light);
}

.header{
  background: var(--white);
  &__logo{}
  &__options{}
  &__option{
    padding: .7rem 1.6rem;
    border-left: 1px solid var(--line);
  }
  &__login-btn{
    margin: -.7rem 0;
  }
  &__user-display{
    min-width: 11rem;
  }
  @include breakpoint(md){
    display: none;
  }
}

.burger{
  width: 3rem;
  height: 2.8rem;
  box-sizing: border-box;
  z-index: 10;
  span{
    width: 100%;
    height: 2px;
    display: block;
    background: var(--font-primary);

    border-radius: 4px;

    transition: .3s;

    &:nth-child(2){
      margin: .75rem 0;
    }
  }
}

.mobile-header{
  display: none;

  padding: 2rem;
  box-sizing: border-box;

  background: var(--white);
  &__logo{}
  &__body{
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    z-index: 101;
  }
  @include breakpoint(md){
    width: 100%;
    display: block;
  }
}

.mobile-menu{
  width: 100%;
  height: calc(100vh - 7.75rem);
  display: block;

  position: absolute;
  left: 0;
  top: 7.75rem;

  opacity: 0;
  transition: var(--qSpeed);

  z-index: -1;

  &__overlay{
    width: 100%;
    height: inherit;
    position: absolute;
    background: var(--brand-dark);
    opacity: .2;
    z-index: 0;
  }
  &__body{
    min-width: 30rem;
    height: inherit;
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: var(--white);
    z-index: 1;

    transform: translateX(-100%);
    transition: var(--mSpeed);
  }
  &__links{
    padding: 2rem 4rem 2rem 2rem;
    box-sizing: border-box;
  }
  &__link{
    display: block;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: .8rem;
    svg{
      fill: var(--font-disabled);
    }
    &.active{
      svg{
        fill: var(--brand);
      }
      span {
        color: var(--brand);
      }
    }
  }
  &__sub-link{
    display: flex;
    margin-top: 1rem;
    padding: 1rem 2rem 1rem 1rem;
    border-radius: .8rem;
    svg{
      margin-right: 2rem;
      fill: var(--font-disabled);
    }
    &.active{
      svg{
        fill: var(--brand);
      }
      span{
        color: var(--brand);
        font-weight: 700;
      }
    }
  }
  &__user-display{
    padding: 2rem 3rem;
    box-sizing: border-box;
    .btn{
      width: 100%;
    }
    .btn-content{
      margin: 0 auto;
    }
  }
}

.menu-open{
  .burger span{
    &:nth-child(1){
      transform: translateY(1rem) rotateZ(45deg);
    }
    &:nth-child(3){
      transform: translateY(-1rem) rotateZ(-45deg);
    }
    &:nth-child(2){
      opacity: 0;
    }
  }
  .mobile-menu{
    opacity: 1;
    z-index: 100;
    &__body{
      transform: none;
    }
  }
}

.theme-switch{
  width: 5.7rem;
  height: 3rem;
  position: relative;
  border-radius: 3.7rem;

  background: var(--brand-bg);
  transition: var(--qSpeed);

  //&.active{
  //  background: var(--brand-dark);
  //}

  &__day-icon, &__night-icon, &__caret {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__day-icon, &__night-icon {
    svg{
      fill: var(--brand-dark);
      transition: var(--mSpeed);
    }
  }
  &__night-icon {
    left: .5rem;
  }
  &__day-icon {
    right: .5rem;
  }
  &__caret {
    width: 2.2rem;
    height: 2.2rem;
    left: .5rem;
    border-radius: 50%;
    background: white;
    box-shadow: 0 1px 2px var(--line);
    transition: var(--qSpeed);
  }
}

.active .theme-switch{
  &__day-icon, &__night-icon{
    svg {
      fill: var(--font-primary);
    }
  }
  &__caret{
    transform: translate(115%, -50%);
  }
}

.lang-switch{
  width: 3.5rem;
}

.divider{
  height: 1px;
  background: var(--line);
}