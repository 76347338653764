.dropdown{
  width: 100%;
  position: relative;
  &.disabled{
    opacity: .95;
    .select-btn{
      span{
        color: var(--font-disabled);
      }
      svg{
        fill: var(--font-disabled);
      }
    }
    button:hover{
      cursor: default;
    }
  }
  &.error{
    .select-btn{
      color: var(--error);
      border-color: var(--error);
      svg{
        fill: var(--error);
      }
    }
  }
}

.dropdown-btn{
  display: flex;
  align-items: center;
  &__caret{
    margin-left: .8rem;
    transition: var(--qSpeed);
    transform: rotate(0);
  }
}

.form{
  .active.select-btn{
    border-color: var(--success);
    .select-btn__label{
      color: var(--success);
    }
    svg{
      fill: var(--success)
    }
  }
  .disabled .active.select-btn{
    border-color: var(--font-disabled);
    .select-btn__label{
      color: var(--font-disabled);
    }
    svg{
      fill: var(--font-disabled);
    }
  }
  .error .active.select-btn{
    border-color: var(--error);
    .select-btn__label{
      color: var(--error);
    }
    svg{
      fill: var(--error);
    }
  }
}

.select-btn{
  width: 100%;
  height: 4.8rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.2rem 1.6rem 1.2rem 1.6rem;

  color: var(--font-primary);
  background: var(--bg-secondary);

  border: 1px solid transparent;
  border-radius: .8rem;

  &__label{
    padding: 0 .4rem;
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;
    line-height: 1;
    transition: var(--qSpeed);
    z-index: 1;
  }
  &__caret{
    margin-left: auto;
  }
  &.active{
    background: var(--white);
    border-color: var(--font-disabled);
    .select-btn__label{
      font-size: 1.2rem;
      top: -.5rem;
      left: 1.6rem;
      background: var(--white);
      color: var(--font-disabled);
    }
  }
}

.table-actions{
  width: fit-content;
  display: block;
  margin: 0 auto;
  svg{
    margin: 0 auto;
  }
}

.dropdown-opened{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  &__content{
    position: absolute;
    padding: 1.6rem 1.6rem .8rem 1.6rem;

    background: var(--white);
    border-radius: .8rem;
    box-sizing: border-box;
    box-shadow: 0px 6px 10px rgba(47, 29, 118, 0.16);

    opacity: 0;
    transition: var(--qSpeed);
    z-index: 5;
  }
  &__btn{
    margin-bottom: .8rem;
  }
  &__body{
    max-height: 15rem;
    margin: .8rem -.8rem 0;
    background: var(--white);
    z-index: 5;
  }
  &__scroll-wrapper{
    overflow-x: hidden;
  }
  &__list-item{
    width: calc(100% + 1.6rem);
    display: block;
    padding: .8rem;
    box-sizing: border-box;
    text-align: left;
    transition: var(--qSpeed);
    &:hover{
      background: var(--brand-bg-light);
    }
  }
  &__bg-layer{
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.action-dropdown{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  &__content{
    position: absolute;
    padding: .8rem 4rem .8rem 1.6rem;

    background: var(--white);
    border-radius: .8rem;
    box-sizing: border-box;
    box-shadow: 0px 6px 10px rgba(47, 29, 118, 0.16);

    opacity: 0;
    transition: var(--qSpeed);
    z-index: 5;
  }
  &__btn{
    position: absolute;
    top: 1.4rem;
    right: 1rem;
  }
  &__body{
    background: var(--white);
    z-index: 5;
  }
  &__list-item{
    width: calc(100% + 1.6rem);
    display: block;
    margin: 0 -.8rem;
    padding: .8rem;
    box-sizing: border-box;
    text-align: left;
    transition: var(--qSpeed);
    &:hover{
      background: var(--brand-bg-light);
    }
  }
  &__bg-layer{
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.shown{
  .dropdown-opened__content, .action-dropdown__content{
    opacity: 1;
  }
  .action-dropdown{
    &__btn{
      svg{
        fill: var(--brand)
      }
    }
  }
  .select-btn{
    background: transparent;
  }
  .dropdown-btn, .select-btn{
    &__caret{
      transform: rotate(180deg);
    }
  }
}