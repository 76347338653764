.modal{
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: -1;
  opacity: 0;

  transition: var(--qSpeed);

  &__cross{
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
  }

  &__body{
    max-width: 47.5rem;
    max-height: 93%;
    width: 100%;

    overflow-y: auto;

    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    padding: 4rem 4rem 3.5rem 4rem;

    box-sizing: border-box;

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 2rem;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 2rem;
    }

  }

  &.open{
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 1;
  }
  @include breakpoint(md){
    &__body{
      max-width: calc(100% - 4rem);
      padding-right: 2rem;
    }
  }
  @include breakpoint(sm){
    &__body{
      padding: 1.5rem;
    }
  }
}

.overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  background: var(--font-primary);
  opacity: .5;
  backdrop-filter: blur(6px);
}